import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { catchError, filter, Observable, of, switchMap, take, tap } from "rxjs";
import { SafeCryptoService } from '../../api/services/safe-crypto.service';
import { Router } from '@angular/router';
import { UserEntity } from '../../api/models/safe-crypto.models';
import { environment } from "../../../environments/environment.development";

@Injectable({
  providedIn: 'root'
})
export class UserControllerService {
  public user!: WritableSignal<UserEntity>;
  private readonly router = inject(Router);
  private readonly _safeCryptoService = inject(SafeCryptoService);

  getUser(queryParamsCodes?: {referralCode?: string | null, reportedHash?: string | null}): Observable<UserEntity | undefined> {
    let body = this._getBodyForLogin();

    // if (!environment.production) {
    //   body = this._getMockBodyForLogin();
    // }

    if (!body) {
      return of(undefined);
    }
    (queryParamsCodes?.referralCode && (body.referralCode = queryParamsCodes?.referralCode));
    (queryParamsCodes?.reportedHash && (body.reportedHash = queryParamsCodes?.reportedHash));

    return this._safeCryptoService.login(body).pipe(
      tap(async (user: { userId: string }) => {
        if (!user) {
          await this.router.navigate(['/401']);
        }
      }),
      filter(Boolean),
      switchMap(({ userId }) => {
        return this._safeCryptoService.getUser(userId).pipe(
          tap((userData: UserEntity) => {
            this.user = signal(userData);
            if (!userData.actualGeo) {
              // this._safeCryptoService.getGeo(userData.id).pipe(take(1)).subscribe({
              //   next: ({actualGeo}: {actualGeo: string}) => {
              //     if (actualGeo) {
              //       this._safeCryptoService.putGeo(userData.id, actualGeo).pipe(take(1)).subscribe({
              //         next: () => {

              //         }
              //       });
              //     }
              //   }
              // })
            }
          }),
        )
      }
      ),
      catchError(async (error) => {
        await this.router.navigate(['/401']);
        throw error;
      })
    );
  }

  refresh(): Observable<UserEntity | undefined> {
    return this.getUser();
  }

  private _getTelegramInfo() {
    const telegramInfo = window.Telegram.WebApp.initDataUnsafe;
    if (telegramInfo?.user) {
      localStorage.setItem('telegramInfo', JSON.stringify(telegramInfo));
      return telegramInfo;
    }

    const storedTelegramInfo = localStorage.getItem('telegramInfo');
    return storedTelegramInfo ? JSON.parse(storedTelegramInfo) : null;
  }

  private _getMockBodyForLogin() {
    return {
      fullName: 'Aleksandr',
      telegramUserId: '393283805',
      telegramUsername: 'AleksandrPylypenko',
      telegramChatId: '393283805'
    }
    // ----------------------------------------
    // return {
    //   telegramUserId: '6791031723',
    //   telegramUsername: 'osckar_qa',
    //   telegramChatId: '6791031723'
    // }
    // ---------------------------------------
  }

  private _getBodyForLogin(): any {
    const telegramData = this._getTelegramInfo();

    if (telegramData) {
      return {
        fullName: ((telegramData.user?.first_name || '') + (telegramData.user?.last_name || '')) || '',
        telegramUserId: telegramData.user?.id,
        telegramUsername: telegramData.user?.username || '',
        telegramChatId: telegramData.chat?.id || telegramData.user?.id
      };
    }

    return null;
  }
}
